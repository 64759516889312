const linksEn = [
	{
		path: '/en/alumni/',
		home: true,
	},
	{
		path: '/en/alumni/alumni-events/',
		name: 'Alumni events',
		active: true,
	},
	{
		path: '/en/alumni/lifelong-learning/',
		name: 'Lifelong learning',
		active: true,
	},
	{
		path: '/en/alumni/clubs/',
		name: 'Clubs',
		active: true,
	},

	{
		path: '/en/alumni/channels/',
		name: 'Channels and communities',
		active: true,
	},

	{
		path: '/en/alumni/loyalty/',
		name: 'Loyalty program',
		active: true,
	},
	{
		path: '/en/alumni/marketplace/',
		name: 'Marketplace',
		active: false,
	},
	{
		path: '/en/alumni/special-offer/',
		name: 'Special offer',
		active: false,
	},
	{
		path: '/alumni/alumni-contacts/',
		name: 'Contacts',
		active: true,
	},
	{
		path: '/alumni/moduli-mba/',
		name: 'MBA',
		active: false,
	},
	{
		path: '/alumni/moduli-emba/',
		name: 'EMBA',
		active: false,
	},
];

export default linksEn;
