const links = [
	{
		path: '/alumni/',
		home: true,
	},
	{
		path: '/alumni/alumni-events/',
		name: 'Мероприятия',
		active: true,
	},
	{
		path: '/alumni/lifelong-learning/',
		name: 'Непрерывное образование',
		active: true,
	},
	{
		path: '/alumni/back-to-school/',
		name: 'Снова в Школу',
		active: true,
	},
	{
		path: '/alumni/clubs/',
		name: 'Клубы',
		active: true,
	},
	{
		path: '/alumni/channels/',
		name: 'Каналы и сообщества',
		active: true,
	},
	{
		path: '/alumni/directory',
		name: 'Директория выпускников',
		active: true,
	},
	{
		path: '/alumni/loyalty/',
		name: 'Программа привилегий',
		active: true,
	},
	{
		path: '/alumni/alumni-contacts/',
		name: 'Контакты',
		active: true,
	},

];

export default links;
