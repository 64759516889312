<template>
	<div>
		<alumni-header v-if="check" />
		<center-header :fetched-data="fetchedData" :links="menuLinks" />

		<div v-if="isAlumni || !notAlumniPage">
			<div v-for="(block, index) in fetchedData.blocks" :key="index">
				<component
					:is="block.type"
					:ref="block.type"
					:index="index"
					:block="block"
					:content="block.content"
					:fetched-data="fetchedData"
					:static_content="block.static_content"
					:block_title="block.block_title"
				/>
			</div>
		</div>
		<main-footer />
	</div>
</template>

<script>
import panels from '~/mixins/panels';
import createJivoMixin from '~/mixins/createJivoMixin';

import CenterHeader from '~/components/layouts/headers/CenterHeader.vue';
import PanelPartnersList from '~/components/dev/PanelPartnersList.vue';
import PanelReview from '~/components/panels/PanelMedia/PanelReview.vue';
import PanelDigitSteps from '~/components/static/PanelDigitSteps.vue';
import MainFooter from '~/components/layouts/footers/MainFooter.vue';
import { mapActions, mapMutations } from 'vuex';
import AlumniHeader from '~/components/alumniHeader.vue';
import makeCanonicalLink from '~/utils/seo/makeCanonicalLink';
import linksEn from './links_en';
import links from './links';

export default {
	name: 'ProjectAlumni',
	components: {
		AlumniHeader,
		CenterHeader,
		PanelPartnersList,
		PanelReview,
		PanelDigitSteps,
		MainFooter,
	},
	mixins: [panels, createJivoMixin('fetchedData.consultation_chat_code')],
	async asyncData({ app, store }) {
		const { $pagesApi } = app;

		const [fetchedData] = await Promise.all([
			$pagesApi.alumni(app.i18n.locale),
			store.dispatch('profile/getRoles'),
		]);

		return { fetchedData };
	},
	data() {
		return {
			fetchedData: [],
			links,
			linksEn,
		};
	},
	head() {
		return {
			title: this.fetchedData.meta_title,
			link: [
				{
					rel: 'canonical',
					hid: 'canonical',
					href: makeCanonicalLink(this.$route.path),
				},
			],
			meta: [
				{
					hid: 'robots',
					name: 'robots',
					content: this.fetchedData.meta_indexing,
				},
				{
					name: 'description',
					hid: 'description',
					content: this.fetchedData.meta_description,
				},
				// Open Graph
				{
					name: 'og:title',
					hid: 'og:title',
					content: this.fetchedData.meta_title,
				},
				{
					name: 'og:description',
					hid: 'og:description',
					content: this.fetchedData.meta_description,
				},
				{
					name: 'og:type',
					hid: 'og:type',
					content: 'website',
				},
				{
					name: 'og:image',
					hid: 'og:image',
					content: this.fetchedData.smm_image_default.src
						|| this.fetchedData.top_cover_image_default.src,
				},
				// Twitter Card
				{
					name: 'twitter:card',
					content: 'summary',
				},
				{
					name: 'twitter:site',
					content: '@nuxt_js',
				},
				{
					name: 'twitter:title',
					content: this.fetchedData.meta_title,
				},
				{
					name: 'twitter:description',
					content: this.fetchedData.meta_description,
				},
				{
					name: 'twitter:image',
					content: this.fetchedData.smm_image_default.src
						|| this.fetchedData.top_cover_image_default.src,
				},

				{
					name: 'image',
					property: 'og:image',
					content: this.fetchedData.smm_image_default.src
						|| this.fetchedData.top_cover_image_default.src,
				},
				{
					name: 'author',
					content: 'SKOLKOVO',
				},
			],
		};
	},
	computed: {
		menuLinks() {
			return this.$i18n.locale === 'en' ? linksEn : links;
		},
		check() {
			return (!this.$auth.user && this.$store.state.alumni.projectAlumni)
				|| (!this.isAlumni && this.fetchedData.is_alumni);
		},
		isAlumni() {
			return this.$store.getters['profile/isAlumni'];
		},
		notAlumniPage() {
			return this.$store.state.alumni.projectAlumni;
		},
	},
	async created() {
		this.checkValue();
		if (this.$store.state.alumni.projectAlumni && !this.$auth.loggedIn) {
			this.showLoginPopup();
		}
	},
	methods: {
		...mapMutations({
			checkAlumni: 'alumni/projectAlumni',
		}),
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
		}),
		checkValue() {
			if (this.fetchedData.is_alumni === true) {
				this.checkAlumni(true);
			}
		},
	},
};
</script>
